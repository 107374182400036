<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			slider: false,
			cat: false,
			recipe: false,
		}
	},
	async mounted() {
		await this.ready();
		this.getSlider()
		this.getRecipe()
	},
	methods: {
		getSlider(){
			$("#FoMain #wrapper > *:not(#header)").css("opacity", 0)
			Gen.apirest("/static-hero", {id:6}, (err,resp)=>{
				this.slider = resp.data
				setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					Gen.loadScript("/frontend/js/custom.js", false)
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
				}, 100)
			})
		},
		getRecipe(){
			this.recipe = false
			let params = Gen.clone(this.$route.query)
			params.cat = this.$route.params.cat
			Gen.apirest("/recipe-list", params, (err, resp)=>{
				this.cat = resp.cat
				this.recipe = resp.recipe
			})
		},
		changePage(page=1){
			this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
		}
	},
	watch:{
		$route(){
			this.getRecipe()
		}
	}
}
</script>
<template>
	<fragment>
		<section id="slider" v-if="slider" class="slider-element  half-slider force-full-screen full-screen clearfix" data-loop="true"
			data-autoplay="5000">
			<div class="force-full-screen full-screen" :style="'background-image: url(\''+uploader(isMobile?slider.ash_mobile_img:slider.ash_img)+'\');'">
			</div>
		</section>

		<!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin">
					<div class="container">
						<div class="recipe-category" v-if="cat">
							<ul>
								<li v-for="(v,k) in cat" data-aos="fade-up" :key="k" :data-aos-delay="50*k">
									<router-link :to="{name:'Resep',params:{cat:v.mrc_slug}}" :class="$route.params.cat==v.mrc_slug ? 'active':(!$route.params.cat&&v.mrc_slug=='all-recipe'?'active':'')">{{v.mrc_name}}</router-link>
								</li>
							</ul>
						</div>
						<div class="text-center">
							<LoadingSpinnerFe light v-if="recipe===false" />
							
							<div v-if="recipe!==false&&!recipe.data.length" class="center">
								<div class="row justify-content-center">
									<div class="col-md-6 col-lg-4">
										<img :src="assets('fo_images', 'empty.svg')" alt="No Results Found">
									</div>
								</div>
								<h3 class="mb-3">Belum ada resep.</h3>
							</div>
						</div>
						<div class="row justify-content-center" v-if="recipe">
							<div v-for="(v,k) in recipe.data" :key="k" class="col-lg-3 col-md-4" data-aos="fade-up" :data-aos-delay="50*k">
								<router-link :to="{name:'DetailResep',params:{slug:v.mr_slug}}" class="recipe-item">
									<div class="ri-img">
										<img :src="uploader(v.mr_image)" alt="Recipe Image">
									</div>
									<div class="ri-desc">
										<!-- <span>{{v.category}}</span> -->
										<h3>{{v.mr_title}}</h3>
									</div>
								</router-link>
							</div>
						</div>
						<Pagination v-if="recipe" :limit="2" :data="recipe" @pagination-change-page="changePage" class="pagination pagination-transparent pagination-circle justify-content-center mb-0">
							<i slot="prev-nav" class="icon-angle-left"></i>
							<i slot="next-nav" class="icon-angle-right"></i>
						</Pagination>
					</div>
				</section>
			</div>

		</section><!-- #content end -->
	</fragment>
</template>